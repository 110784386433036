import React, { useEffect, useState } from 'react';
import { Box, Card, CircularProgress, Grid, IconButton, Modal, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DashboardService from '@/services/dashboard';
import { useTranslation } from '@/hooks/translations';
import { Download } from '@mui/icons-material';

interface CustomerInsightProps {
    id: string;
}

const CustomerInsight: React.FC<CustomerInsightProps> = ({ id }) => {
    const dashboardService = DashboardService.getInstance();
    const { t } = useTranslation('common');

    const [open, setOpen] = useState<boolean>(false);
    const [customerInsightUrl, setCustomerInsightUrl] = useState<string>('');
    const [customerInsightLoading, setCustomerInsightLoading] = useState<boolean>(false);

    const getCustomerInsight = () => {
        setCustomerInsightLoading(true);
        dashboardService.getCustomerInsight(id || '').then((res) => {
            if (res?.data?.url !== '') {
                setCustomerInsightUrl(res.data.url);
                setOpen(true);
                setCustomerInsightLoading(false);
            }
        });
    };

    useEffect(() => {
        const interval = setInterval(() => {
            if (customerInsightUrl === '' && customerInsightLoading) {
                getCustomerInsight();
            }
        }, 60000);
        return () => clearInterval(interval);
    }, [customerInsightUrl, customerInsightLoading]);

    return (
        <>
            <Grid item xs={12} sm={6} md={6} lg={3}>
                <Card
                    sx={{
                        maxWidth: '100%',
                        border: '1px solid #e0e0e0',
                        borderRadius: '8px',
                        boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
                        paddingLeft: '10px',
                        paddingRight: '10px',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        minHeight: '80px',
                    }}
                >
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <Typography variant="button" component="div" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
                            {t('Customer Insights')}
                        </Typography>
                        <Typography variant="caption" color="text.secondary">
                            {t('Get your last months customer insights.')}
                        </Typography>
                    </Box>
                    <IconButton
                        onClick={getCustomerInsight}
                        sx={
                            !customerInsightLoading
                                ? {
                                      width: '40px',
                                      height: '40px',
                                  }
                                : {}
                        }
                        disabled={customerInsightLoading}
                    >
                        {customerInsightLoading ? <CircularProgress /> : <Download sx={{ color: 'primary.main' }} />}
                    </IconButton>
                </Card>
            </Grid>

            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="pdf-viewer-modal"
                aria-describedby="pdf-viewer-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        bgcolor: 'rgba(0, 0, 0, 0.9)',
                    }}
                >
                    {/* Close button */}
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 16,
                            right: 16,
                            zIndex: 10,
                        }}
                    >
                        <IconButton
                            onClick={() => setOpen(false)}
                            sx={{
                                color: 'white',
                                bgcolor: 'rgba(0, 0, 0, 0.5)',
                                '&:hover': {
                                    bgcolor: 'rgba(0, 0, 0, 0.7)',
                                },
                            }}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>

                    {/* PDF container */}
                    <Box
                        sx={{
                            position: 'absolute',
                            display: 'flex',
                            justifyContent: 'center',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            overflow: 'auto',
                            width: '100%',
                            height: '100%',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                minHeight: '100%',
                                width: '100%',
                            }}
                        >
                            <iframe
                                src={customerInsightUrl}
                                title="PDF Viewer"
                                style={{ width: '100%', height: '100%' }}
                            />
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </>
    );
};

export default CustomerInsight;
